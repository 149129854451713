import React from 'react';
import {Marker} from "react-map-gl";
import {Structure, Valve} from "../structure"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAdjust} from '@fortawesome/free-solid-svg-icons'
import {add, divide, pointToWGS84} from "../geo";
import {classNames} from "../utils";

interface Props {
    valve: Valve
    selectedStructure?: Structure
    setSelectedStructure: (structure: Structure) => void
}

const ValveMarker: React.FC<Props> = ({valve, selectedStructure, setSelectedStructure}) => {
    const position = divide(add(pointToWGS84(valve.first.point), pointToWGS84(valve.second.point)), 2)
    const size = 6
    const baseClasses = `shadow-md rounded-full cursor-pointer h-6 w-6 flex items-center justify-center bg-white text-sm`
    const selectionClasses = selectedStructure?.id === valve.id && selectedStructure?.type === "VALVE" ?
        "border-2 border-blue-700 bg-blue-500 text-white font-bold" :
        "border-2 border-blue-400 bg-gray-50"

    const onClick = () => {
        setSelectedStructure(valve)
    }

    return (
        <Marker {...position} key={valve.id} offsetLeft={-2 * size} offsetTop={-2 * size}>
            <div className={classNames(baseClasses, selectionClasses)} onClick={onClick}>
                <FontAwesomeIcon icon={faAdjust}/>
            </div>
        </Marker>
    );
};

export default ValveMarker;