import React from 'react';
import {Marker} from "react-map-gl";
import {Node, Structure} from "../structure"
import {pointToWGS84} from "../geo";
import {classNames, toGPM} from "../utils";

interface Props {
    node: Node
    selectedStructure: Structure | undefined
    setSelectedStructure: (structure: Structure) => void
}

const NodeMarker: React.FC<Props> = ({node, selectedStructure, setSelectedStructure}) => {
    const size = 12
    const position = pointToWGS84(node.point);
    const demand = toGPM(node.demand).toFixed(2)
    const baseClasses = `shadow-md cursor-pointer rounded-full h-12 w-12 flex items-center justify-center bg-white text-sm`
    const selectionClasses = selectedStructure?.id === node.id && selectedStructure?.type === "NODE" ?
        "border-2 border-blue-700 bg-blue-500 text-white font-bold" :
        "border-2 border-blue-400 bg-gray-50"

    const onClick = () => {
        setSelectedStructure(node)
    }

    return (
        <Marker {...position} key={node.id} offsetLeft={-2 * size} offsetTop={-2 * size}>
            <div
                className={classNames(baseClasses, selectionClasses)}
                onClick={onClick}
            >
                <div className="grid grid-cols-1 text-xxs">
                    <div className="flex items-center justify-center">{demand}</div><div className="flex items-center justify-center">gpm</div>
                </div>
            </div>
        </Marker>
    )
}

export default NodeMarker;