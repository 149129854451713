import axios from "axios";
import {Link, Network, Node, Reservoir, Valve} from "./structure";
import {eachHourFromHoursAgo} from "./utils";
import {formatISO} from "date-fns";

const apiRoot = process.env.REACT_APP_API_ROOT

export const fetchServerTime = async (): Promise<Date> => {
    const url = apiRoot + '/time'
    const response = await axios.get(url)
    return new Date(Date.parse(response.data.time))
}

export const fetchNetwork = async (time: Date): Promise<Network> => {
    const url = `${apiRoot}/simulatedNetwork/${formatISO(time)}`
    const response = await axios.get(url)
    const network = response.data
    return {
        nodes: network.nodes.map((node: Node) => {
            node.type = "NODE"
            return node
        }),
        tanks: network.tanks,
        reservoirs: network.reservoirs.map((reservoir: Reservoir) => {
            reservoir.type = "RESERVOIR"
            return reservoir
        }),
        links: network.links.map
        (
            (link: Link) => {
                link.type = "LINK"
                return link
            }
        )
        ,
        pumps: network.pumps,
        valves: network.valves.map((valve: Valve) => {
            valve.type = "VALVE"
            return valve
        })
    }
}

export const fetchNetworkTimeRange = async (end: Date, hoursAgo: number) => {
    const hours = eachHourFromHoursAgo(end, hoursAgo)
    const map = new Map<string, Network>()
    const results: Promise<void>[] = hours.map((hour) => {
            const key = formatISO(hour).toString()
            return fetchNetwork(hour).then((network) => {
                map.set(key, network)
            })
        }
    )
    await Promise.all(results)
    return map
}

export const fetchNode = (id: string, time: Date) => fetchNetwork(time).then((network: Network) => {
    return network.nodes.find(node => node.id === id)
})

export const fetchLink = (id: string, time: Date) => fetchNetwork(time).then((network: Network) => {
    return network.links.find(link => link.id === id)
})

export const fetchNodeTimeRange = (id: string, end: Date, hoursAgo: number) => fetchNetworkTimeRange(end, hoursAgo).then((networks: Map<string, Network>) => {
    const nodeMap = new Map<string, Node>()
    networks.forEach((network, hour) => {
        const foundNode = network.nodes.find(n => n.id === id)
        foundNode && nodeMap.set(hour, foundNode)
    })
    return nodeMap
})

export const fetchLinkTimeRange = (id: string, end: Date, hoursAgo: number) => fetchNetworkTimeRange(end, hoursAgo).then((networks: Map<string, Network>) => {
    const linkMap = new Map<string, Link>()
    networks.forEach((network, hour) => {
        const foundLink = network.links.find(n => n.id === id)
        foundLink && linkMap.set(hour, foundLink)
    })
    return linkMap
})
