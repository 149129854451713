import {eachHourOfInterval, sub} from "date-fns";

export const classNames = (...names: string[]) => {
    return names.join(' ')
}

export const eachHourFromHoursAgo = (time: Date, ago: number) => {
   return eachHourOfInterval({start: sub(time, {hours: ago}), end: time})
}

export const toGPM = (cfps: number) => {
    return cfps / 0.002228009
}