import React from 'react';
import {Marker} from "react-map-gl";
import {Pump} from "../structure"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFilter} from '@fortawesome/free-solid-svg-icons'
import {pointToWGS84} from "../geo";

interface Props {
    pump: Pump
}

const PumpMarker: React.FC<Props> = ({pump}) => {
    const position = pointToWGS84(pump.first.point);

    return (
        <Marker {...position} key={pump.id} offsetLeft={-8} offsetTop={-12}>
            <FontAwesomeIcon icon={faFilter} onClick={() => console.log("clicked " + pump.id)}/>
        </Marker>
    );
};

export default PumpMarker;