import React from 'react'
import {Structure} from "../structure";
import NodeDetail from "./NodeDetail";
import LinkDetail from "./LinkDetail";
import ReservoirDetail from "./ReservoirDetail";
import ValveDetail from "./ValveDetail";

interface Props {
    structure?: Structure
    hour: Date
}

const StructureDetail: React.FC<Props> = ({structure, hour}) => {
    switch(structure?.type) {
        case "NODE": return <NodeDetail node={structure} hour={hour} />
        case "RESERVOIR": return <ReservoirDetail reservoir={structure} />
        case "VALVE": return <ValveDetail valve={structure} />
        case "LINK": return <LinkDetail link={structure} />
    }
    return <></>;
}

export default StructureDetail