import React from 'react'


interface Props {
    details: Map<string, string>
}

const StructureDetailsTable: React.FC<Props> = ({details}) => {
    return (
        <div className="">
            <dl className="">
                {Array.from(details.entries()).map((entry: [string, string]) => {
                        const [key, value] = entry
                        return (
                            <div className="py-1 sm:py-1 sm:grid sm:grid-cols-3">
                                <dt className="text-sm font-medium text-gray-500">{key}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
                            </div>
                        )
                    }
                )}
            </dl>
        </div>
    )
}

export default StructureDetailsTable