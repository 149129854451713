import React, {useEffect, useState} from 'react'
import NetworkMap from '../components/NetworkMap'
import Sidebar from "../components/Sidebar"
import {Network, Structure} from '../structure'
import {fetchNetwork, fetchServerTime} from "../Client";
import {startOfHour} from "date-fns";
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'

const App = () => {
    const [network, setNetwork] = useState<Network | undefined>()
    const [viewHour, setViewHour] = useState<Date>(new Date())
    const [selectedStructure, setSelectedStructure] = useState<Structure | undefined>()

    useEffect(() => {
        fetchServerTime().then(time => setViewHour(startOfHour(time)))
    }, [])

    useEffect(() => {
        fetchNetwork(viewHour).then(setNetwork)
    }, [viewHour])

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: theme.palette.background.default,
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
    }))
    const classes = useStyles()

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                {network &&
                <div className={classes.root}>
                    <CssBaseline/>
                    <main className={classes.content}>
                        <NetworkMap network={network} selectedStructure={selectedStructure}
                                    setSelectedStructure={setSelectedStructure}/>
                    </main>
                    <Sidebar network={network}
                             selectedStructure={selectedStructure}
                             setSelectedStructure={setSelectedStructure}
                             viewHour={viewHour}
                             setViewHour={setViewHour}
                    />
                </div>
                }
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default App
