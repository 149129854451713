import React, {useEffect, useState} from 'react'
import {Network, Node} from '../structure';
import {useTimeContext} from '../contexts/TimeContext';
import {fetchNetworkTimeRange} from '../Client';
import TimeSeriesChart, {Datum} from './TimeSeriesChart';
import _ from 'lodash';
import {Card} from "@material-ui/core";

interface Props {
    network: Network
    hour: Date
}

const NetworkOverview: React.FC<Props> = ({network, hour}) => {
    const [networkHistory, setNetworkHistory] = useState<Map<string, Network>>(new Map())

    useEffect(() => {
        fetchNetworkTimeRange(hour, 24).then((networkMap) => networkMap && setNetworkHistory(networkMap))
    }, [hour])


    let data: [Date, number][] = []
    networkHistory.forEach((value, key) => {
        const positiveNodes = value.nodes.filter(node => node.demand > 0 && node.demand < 0.1)
        const date = new Date(Date.parse(key))
        data.push([date, _.sumBy(positiveNodes, (node) => node.demand)])
    })
    const tsData = _.sortBy(data, (datum) => datum[0]).map ( d => {
            const [date, demand] = d
            return {name: date.toString(), value: demand}
        }
    )

    return (
        <Card className="px-3 pt-2">
            <h3>Network Demand Previous 24 Hours</h3>
            <TimeSeriesChart data={tsData}/>
        </Card>
    )
}

export default NetworkOverview