import React from 'react'
import {Valve} from '../structure'
import StructureDetailsTable from "./StructureDetailsTable";
import {Card} from "@material-ui/core";

interface Props {
    valve: Valve
}

const ValveDetail: React.FC<Props> = ({valve}) => {
    return (
        <Card className="my-5 px-3 pt-2">
            <h2>Valve {valve.id}</h2>
            <StructureDetailsTable details={new Map([
                ['Upstream', valve.first.id],
                ['Downstream', valve.second.id],
                ['Setting', valve.setting.toFixed(2)]
            ])}/>
        </Card>
    )
}

export default ValveDetail