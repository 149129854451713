import React from 'react'
import {Reservoir} from '../structure'
import StructureDetailsTable from "./StructureDetailsTable";
import {Card} from "@material-ui/core";

interface Props {
    reservoir: Reservoir
}

const ReservoirDetail: React.FC<Props> = ({reservoir}) => {
    return (
        <Card className="my-5 px-3 pt-2">
            <h2>Reservoir {reservoir.id}</h2>
            <StructureDetailsTable details={new Map([
                ['X', reservoir.point.x.toFixed(2)],
                ['Y', reservoir.point.y.toFixed(2)]])}/>
        </Card>
    )
}

export default ReservoirDetail