import React, {useEffect, useState} from 'react'
import {Link} from '../structure'
import {fetchLink, fetchLinkTimeRange} from "../Client";
import {useTimeContext} from "../contexts/TimeContext";
import TimeSeriesChart, {Datum} from "./TimeSeriesChart";
import {getHours} from 'date-fns';
import _ from 'lodash'
import StructureDetailsTable from "./StructureDetailsTable";
import {Box, Card} from "@material-ui/core";
import {toGPM} from "../utils";

interface Props {
    link: Link
}

const LinkDetail: React.FC<Props> = ({link}) => {
    const [linkHistory, setLinkHistory] = useState<Map<string, Link>>(new Map())

    const timeContext = useTimeContext()

    useEffect(() => {
        fetchLinkTimeRange(link.id, timeContext.serverTime, 24).then((linkMap) => linkMap && setLinkHistory(linkMap))
    }, [link])

    let data: Datum[] = []
    linkHistory.forEach((value, key) => {
        data.push({name: new Date(Date.parse(key)).toString(), value: value.flow})
    })
    data = _.sortBy(data, (datum) => datum.name)

    return (

        <Card className="my-5 px-3 pt-2">
            <h2>Link {link.id}</h2>
            <StructureDetailsTable details={new Map([
                ['Flow', toGPM(link.flow).toFixed(2) + ' gpm'],
                ['Total Head Loss', link.totalHeadLoss.toFixed(2)],
                ['Velocity', link.velocity.toFixed(2) + ' ft/s'],
                ['Setting', link.setting.toFixed(2)],
                ['Status', link.status.toFixed(0)],
                ])}/>

            <h3 className="text-gray-700 py-2 pt-5">Flow Previous 24 Hours</h3>
            <TimeSeriesChart data={data}/>
        </Card>
    )
}

export default LinkDetail