import React from 'react';
import {Marker} from "react-map-gl";
import {Tank} from "../structure"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWindowMaximize} from '@fortawesome/free-solid-svg-icons'
import {pointToWGS84} from "../geo";

interface Props {
    tank: Tank
}

const TankMarker: React.FC<Props> = ({tank}) => {
    const position = pointToWGS84(tank.point);

    return (
        <Marker key={tank.id} {...position} offsetTop={-8} offsetLeft={-8}>
            <FontAwesomeIcon icon={faWindowMaximize}/>
        </Marker>
    );
};

export default TankMarker;