import React from "react";

interface TimeProps {
    serverTime: Date
    serverHour: Date
    serverHourNum: number
}

export const TimeContext = React.createContext<TimeProps>({serverTime: new Date(), serverHour: new Date(), serverHourNum: (new Date()).getHours()})
export const useTimeContext = () => React.useContext(TimeContext)
