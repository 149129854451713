import React from "react";
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import {CartesianGrid, Line, AreaChart, Tooltip, XAxis, YAxis, Area} from "recharts";

export interface Datum {
    name: string
    value: number
}

interface Props {
    data: Datum[]
}

const TimeSeriesChart: React.FC<Props> = ({data}) => {
    return (
        <AreaChart width={360} height={240} data={data}>
            <Area dataKey="value" animationDuration={20}/>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"  />
            <Tooltip labelFormatter={(label: string) => format(new Date(label), 'MM/dd/yy HH:mm')} formatter={(value: number) => value.toFixed(2) + ' gpm'} />
        </AreaChart>
    )
}

export default TimeSeriesChart