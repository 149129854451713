import {Point} from './structure'
import proj4 from 'proj4'

const statePlaneProj = '+proj=lcc +lat_1=31.88333333333333 +lat_2=30.11666666666667 +lat_0=29.66666666666667 +lon_0=-100.3333333333333 +x_0=699999.9998983998 +y_0=3000000 +datum=NAD83 +units=us-ft +no_defs'

export type LatLongCoordinate = {latitude: number, longitude: number}

export const pointToWGS84 = (point: Point): LatLongCoordinate => {
    try {
        const [long, lat] = proj4(statePlaneProj, 'EPSG:4326', [point.x, point.y])
        return {latitude: lat, longitude: long}
    } catch (err) {
        console.log(err)
        return {latitude: 0.0, longitude: 0.0}
    }
}

export const add = (a: LatLongCoordinate, b: LatLongCoordinate): LatLongCoordinate => {
    return {latitude: a.latitude + b.latitude, longitude: a.longitude + b.longitude}
}

export const divide = (coord: LatLongCoordinate, divisor: number): LatLongCoordinate => {
    return {latitude: coord.latitude / divisor, longitude: coord.longitude / divisor}
}
