import React from 'react'
import {DatePicker} from "@material-ui/pickers";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {Box, FormControl, Grid, MenuItem} from "@material-ui/core";
import Select from '@material-ui/core/Select'
import _ from 'lodash'

interface Props {
    hour: Date
    setHour: (hour: Date) => void
}

const DateHourSelect: React.FC<Props> = ({hour, setHour}) => {
    const onDateChange = (date: MaterialUiPickersDate) => {
        if(!date) return;
        const newHour = _.clone(date)
        newHour.setHours(hour.getHours())
        setHour(newHour)
    }

    const onHourChange = (e: any) => {
        const newHour = _.clone(hour)
        newHour.setHours(e.target.value)
        setHour(newHour)
    }

    const hourMenuItems = []
    for (let i = 0; i < 24; i++) {
        hourMenuItems.push(<MenuItem value={i}>{i.toString()}:00</MenuItem>)
    }

    return (
        <Box mb={3}>
            <Grid container spacing={3} justify="center">
                <Grid item>
                    <FormControl>
                        <DatePicker variant="inline" onChange={onDateChange} value={hour}/>
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl>
                        <Select
                            value={hour.getHours()}
                            onChange={onHourChange}
                        >
                            {hourMenuItems}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DateHourSelect