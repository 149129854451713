import React from 'react';
import {Link, Structure} from "../structure";
import {Layer, LayerProps, Source} from "react-map-gl";
import {pointToWGS84} from "../geo";
import * as GeoJSON from "geojson";

interface Props {
    links: Link[]
    selectedStructure?: Structure
}

const LinkLines: React.FC<Props> = ({links, selectedStructure}) => {
        function link2GeoJSON(link: Link) {
            const firstPosition = pointToWGS84(link.first.point);
            const secondPosition = pointToWGS84(link.second.point);
            const coordinates = [[firstPosition.longitude, firstPosition.latitude]].concat(link.vertices.map(p => pointToWGS84(p)).map(p => [p.longitude, p.latitude])).concat([[secondPosition.longitude, secondPosition.latitude]])

            const geojson: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {
                type: 'Feature',
                properties: {linkId: link.id},
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates
                },

            }
            return geojson
        }

        const unselectedLinks: GeoJSON.FeatureCollection = {
            type: 'FeatureCollection',
            features: links.filter(link => selectedStructure !== link).map(link => link2GeoJSON(link))
        }

        const selectedLink: GeoJSON.Feature | undefined = selectedStructure?.type === 'LINK' ? link2GeoJSON(selectedStructure) : undefined

        const layerStyle: LayerProps =
            {
                type: 'line',
                paint: {
                    'line-color': '#60a5fa',
                    'line-width': 3
                }
            }
        const selectedLayerStyle: LayerProps = {
            ...layerStyle,
            paint: {
                'line-color': '#1d4ed8',
                'line-width': 4
            }
        }

        return (
            <>
                <Source id="links-source" type="geojson" data={unselectedLinks}>
                    <Layer id="links" {...layerStyle} />
                </Source>
                {selectedLink &&
                <Source id="selected-link-source" type="geojson" data={selectedLink}>
                    <Layer id="selected-link" {...selectedLayerStyle} />
                </Source>
                }

            </>
        )
    }
;

export default LinkLines;