import React from 'react';
import {Marker} from "react-map-gl";
import {Reservoir, Structure} from "../structure"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWater} from '@fortawesome/free-solid-svg-icons'
import {pointToWGS84} from "../geo";
import {classNames} from "../utils";

interface Props {
    reservoir: Reservoir
    selectedStructure?: Structure
    setSelectedStructure: (structure: Structure) => void
}

const ReservoirMarker: React.FC<Props> = ({reservoir, selectedStructure, setSelectedStructure}) => {
    const position = pointToWGS84(reservoir.point)
    const size = 10
    const baseClasses = `shadow-md cursor-pointer h-10 w-10 flex items-center justify-center bg-white text-sm`
    const selectionClasses = selectedStructure?.id === reservoir.id && selectedStructure?.type === "RESERVOIR" ?
        "border-2 border-blue-700 bg-blue-500 text-white font-bold" :
        "border-2 border-blue-400 bg-gray-50"

    const onClick = () => {
        setSelectedStructure(reservoir)
    }

    return (
        <Marker key={reservoir.id} {...position} offsetTop={-2 * size} offsetLeft={-2 * size}>
            <div className={classNames(baseClasses, selectionClasses)} onClick={onClick}>
                <FontAwesomeIcon icon={faWater}/>
            </div>
        </Marker>
    );
};

export default ReservoirMarker;