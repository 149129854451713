import {Network, Structure} from '../structure'
import React from 'react'
import StructureDetail from './StructureDetail'
import NetworkOverview from './NetworkOverview'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import DateHourSelect from "./DateHourSelect";

interface Props {
    network: Network
    selectedStructure?: Structure
    setSelectedStructure: (structure: Structure) => void
    viewHour: Date
    setViewHour: (hour: Date) => void
}

const Sidebar: React.FC<Props> = ({network, selectedStructure, setSelectedStructure, viewHour, setViewHour}) => {
    const drawerWidth = 400;
    const useStyles = makeStyles((theme) => ({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            padding: theme.spacing(1),
            backgroundColor: "#fafafa"
        },
    }))
    const classes = useStyles()

    return (
        <Drawer
            variant="permanent"
            className={classes.drawer}
            classes={{
                paper: clsx(classes.drawerPaper),
            }}
            anchor="right"
        >
            <div><DateHourSelect hour={viewHour} setHour={setViewHour} /></div>
            <Box><NetworkOverview network={network} hour={viewHour}/></Box>
            <Box><StructureDetail structure={selectedStructure} hour={viewHour} /></Box>
        </Drawer>
    )
}

export default Sidebar