import React, {useState} from 'react'
import ReactMapGL, {MapEvent} from 'react-map-gl'
import {Network, Structure} from '../structure'
import NodeMarker from './NodeMarker'
import TankMarker from './TankMarker'
import LinkLines from './LinkLines'
import PumpMarker from './PumpMarker'
import ValveMarker from './ValveMarker'
import _ from 'lodash'
import {pointToWGS84} from '../geo'
import ReservoirMarker from "./ReservoirMarker";

interface Props {
    network: Network
    selectedStructure?: Structure
    setSelectedStructure: (structure: Structure) => void
}

const NetworkMap: React.FC<Props> = ({network, selectedStructure, setSelectedStructure}) => {
    const accessToken = 'pk.eyJ1IjoidXRpbGltYXRpY3MiLCJhIjoiY2twcTNuc2M3MDJlajJvcG9xbDhvcmoyYyJ9.yvYLgA__4rna3J8x9Rcw5g'

    const findCenter = () => {
        const centerPoint = _.reduce(network.nodes, (acc, node) => {
            // incrementally average x and y
            return {x: acc.x + (node.point.x - acc.x) / acc.n, y: acc.y + (node.point.y - acc.y) / acc.n, n: acc.n + 1}
        }, {x: 0, y: 0, n: 1})
        return pointToWGS84(centerPoint)
    }
    const [viewport, setViewport] = useState({
        zoom: 17,
        ...findCenter()
    })

    const props = React.useMemo(() => {
        return {selectedStructure, setSelectedStructure}
    }, [selectedStructure, setSelectedStructure])

    const onClick = (event: MapEvent) => {
        if (
            event &&
            event.features &&
            event.features.length > 0 &&
            event.features[0].properties
        ) {
            const properties = event.features[0].toJSON().properties
            if (properties.linkId) {
                const link = network.links.find(l => l.id === properties.linkId)
                link && setSelectedStructure(link)
            }
        }
    };

    const nodeMarkers = React.useMemo(() => network.nodes.map(node => (
            <NodeMarker key={node.id} node={node} {...props} />
        )
    ), [props, network.nodes])

    const tankMarkers = React.useMemo(() => network.tanks.map(tank => (
            <TankMarker key={tank.id} tank={tank} {...props} />
        )
    ), [props, network.tanks])

    const reservoirMarkers = React.useMemo(() => network.reservoirs.map(reservoir => (
            <ReservoirMarker key={reservoir.id} reservoir={reservoir} {...props} />
        )
    ), [props, network.reservoirs])

    const linkLines = React.useMemo(() =>
            <LinkLines links={network.links}  {...props}/>
        , [props, network.links])

    const pumpMarkers = React.useMemo(() => network.pumps.map(
        pump => (
            <PumpMarker key={pump.id} pump={pump} {...props}/>
        )
    ), [props, network.pumps])

    const valveMarkers = React.useMemo(() => network.valves.map(
        valve => (
            <ValveMarker key={valve.id} valve={valve} {...props}/>
        )
    ), [props, network.valves])

    return (
        <div>
            <ReactMapGL
                {...viewport}
                mapboxApiAccessToken={accessToken}
                mapStyle="mapbox://styles/mapbox/light-v10"
                width="100%"
                height="100vh"
                onViewportChange={setViewport}
                onClick={onClick}
                clickRadius={2}
                interactiveLayerIds={['links']}
            >
                {nodeMarkers}
                {tankMarkers}
                {reservoirMarkers}
                {pumpMarkers}
                {valveMarkers}
                {linkLines}
            </ReactMapGL>
        </div>
    )
}

export default NetworkMap