import React, {useEffect, useState} from 'react'
import {Node} from '../structure'
import {fetchNodeTimeRange} from "../Client";
import TimeSeriesChart, {Datum} from "./TimeSeriesChart";
import _ from 'lodash'
import StructureDetailsTable from "./StructureDetailsTable";
import {Card} from "@material-ui/core";
import {toGPM} from "../utils";

interface Props {
    node: Node
    hour: Date
}

const NodeDetail: React.FC<Props> = ({node, hour}) => {
    const [nodeHistory, setNodeHistory] = useState<Map<string, Node>>(new Map())


    useEffect(() => {
        fetchNodeTimeRange(node.id, hour, 24).then((nodeMap) => setNodeHistory(nodeMap))
    }, [node, hour])

    let data: [Date, number][] = []
    nodeHistory.forEach((value, key) => {
        const date = new Date(Date.parse(key))
        data.push([date, value.demand])
    })
    const tsData = _.sortBy(data, (datum) => datum[0]).map(d => {
            const [date, demand] = d
            return {name: date.toString(), value: toGPM(demand)}
        }
    )

    return (
        <Card className="my-5 px-3 pt-2">
            <h2>Node {node.id}</h2>
            <StructureDetailsTable details={new Map([
                ['Demand', toGPM(node.demand).toFixed(2) + ' gpm'],
                ['Head', node.head.toFixed(2) + ' ft'],
                ['Pressure', (node.pressure * 0.4333 ).toFixed(2) + ' psi'],
                ['Elevation', node.elevation.toFixed(2) + ' ft'],
                ['X', node.point.x.toFixed(2)],
                ['Y', node.point.y.toFixed(2)]
            ])}/>
            <h3 className="text-gray-700 py-2 pt-5">Demand Previous 24 Hours</h3>
            <TimeSeriesChart data={tsData}/>
        </Card>
    )
}

export default NodeDetail